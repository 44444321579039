import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import routes from '@/app/utils/routes';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};
export default function SecuredRoute({ children }: Props) {
  const { data: session, status } = useSession();
  const router = useRouter();

  if (status === 'authenticated') {
    return children;
  }

  if (status === 'unauthenticated') {
    router.push(routes.login);
  }

  return null;
}
