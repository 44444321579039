import { DateTime } from 'luxon';
import { FormatDateEnum, getEnvironment, LangEnum } from '@/app/utils/constants';

export const getWeekdayName = (date: string | null | Date, locale: LangEnum = LangEnum.EN_US) => {
  if (!date) return null;
  const dateString = typeof date === 'string' ? date : date.toISOString();
  return DateTime.fromISO(dateString, { zone: 'utc', locale }).weekdayLong;
};

export const formatDate = (date: string | null | Date, format = FormatDateEnum.DATE_TIME) => {
  const invalidDate = '-';
  if (!date) return invalidDate;
  const dateString = typeof date === 'string' ? date : date.toISOString();
  const formattedDate = DateTime.fromISO(dateString, { zone: 'utc' }).toFormat(format);
  const isInvalidDate = formattedDate.includes('Invalid');
  return isInvalidDate ? invalidDate : formattedDate;
};

export const addHoursForward = (date: string | Date | null, addHours: number): string => {
  const invalidDate = '-';
  if (!date) return invalidDate;
  const dateTime =
    typeof date === 'string' ? DateTime.fromJSDate(new Date(date)) : DateTime.fromJSDate(date);
  const newDateTime = dateTime.plus({ hours: addHours });
  // format the date without the timezone offset and with the T
  const formattedDate = newDateTime.toFormat("yyyy-MM-dd'T'HH:mm:ss");
  return formattedDate || invalidDate;
};

/**
 * Converts Date to the short format - 8/17/2023 (US format fow now)
 * Should be adjusted for other locales in the future
 */
export const getShortDate = (date: Date) => date.toLocaleString('en-US', { dateStyle: 'short' });

export const openPDF = (pdfUrl: string) => {
  window.open(pdfUrl, '_blank');
};

// Format 10 digit string as xxx-xxx-xxxx
export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : '';
};

// Format 10 digit string as (xxx) xxx-xxxx
export const formatPhoneNumberWithParens = (phoneNumber?: string) => {
  return phoneNumber ? phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';
};

/**
 * Method for getting and parsing file name from Content-Disposition header
 * for example `attachment; filename=\"34711141 - Project Estimate.pdf\"; filename*=UTF-8''34711141%220-%20Project Estimate.pdf`
 * -> 34711141 - Project Estimate.pdf
 */
export const getFileNameFromContentDispositionHeader = (input: string) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(input);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }
  return '';
};

export const savePDF = (file: any, fileName?: string) => {
  const defaultFileName = 'File';
  const blob = new Blob([file], { type: 'application/pdf' });
  const fileURL = window.URL.createObjectURL(blob);
  let alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = `${fileName || defaultFileName}.pdf`;
  alink.click();
};

export const isRouteInPathname = (route: string, pathname: string) =>
  pathname.indexOf(route) !== -1;

export const convertSecondsToMilliseconds = (seconds: number) => seconds * 1000;

export const addQueryParameterToRoute = (
  route: string,
  target: string,
  paramName: string,
  paramVal: string,
) => {
  if (route.includes(target)) {
    if (route.includes('?')) {
      return `${route}&${paramName}=${paramVal}`;
    } else {
      return `${route}?${paramName}=${paramVal}`;
    }
  }
  return route;
};

export const isWhitespaceString = (str: string) => !str.replace(/\s/g, '').length;
