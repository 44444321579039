'use client';
import { ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Footer from '@/app/components/navigation/Footer';

type Props = {
  children: ReactNode;
};

export default function WorkOrderLayout({ children }: Props) {
  return (
    <Container fluid id="workOrderLayout" className="workOrderLayout">
      <Row className="mt-layout">
        <Col className="content-with-side-menu content-column">
          <main className="pt-3">
            <Container className="pt-2 pt-xl-3 px-0" fluid>
              {children}
            </Container>
          </main>
        </Col>
        <Footer />
      </Row>
    </Container>
  );
}
