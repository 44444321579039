const routes = {
  home: '/',
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  resetPasswordSuccess: '/reset-password-success',
  profile: '/profile',
  defaultRoute: '/work-order',
  workOrder: '/work-order',
  paymentHistory: '/payment-history',
  payment: '/payment',
  signDocument: '/sign-document',
  signNow: '/sign-now',
  ingressAuth: '/ingress-auth',
  signOut: '/sign-out'
};

export default routes;
