'use client';
import { ReactNode } from 'react';
import WorkOrderLayout from '@/app/components/layouts/WorkOrderLayout';
import SecuredRoute from '@/app/components/routes/SecuredRoute';

type Props = {
  children: ReactNode;
};

export default function Layout({ children }: Props) {
  return (
    <SecuredRoute>
      <WorkOrderLayout>{children}</WorkOrderLayout>
    </SecuredRoute>
  );
}
