import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import Link, { LinkProps } from 'next/link';
import { usePathname } from 'next/navigation';
import cn from 'classnames';
import { TextDecorationEnum } from '@/app/utils/constants';

type Props = {
  children: ReactNode;
  className?: string;
  isActive?: boolean;
  textDecoration?: TextDecorationEnum;
  linkPrimary?: boolean;
  target?: HTMLAttributeAnchorTarget;
} & LinkProps;

export default function CustomLink({
  href,
  children,
  className = '',
  isActive = false,
  textDecoration = TextDecorationEnum.underline,
  linkPrimary = true,
  ...rest
}: Props) {
  const pathname = usePathname();
  const active = pathname === href || isActive;

  return (
    <Link
      {...rest}
      href={href || '#'}
      className={cn(`${textDecoration} ${className}`, {
        ['active']: active,
        ['link-primary']: linkPrimary,
      })}
    >
      {children}
    </Link>
  );
}
